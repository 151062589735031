<template>
    <a-drawer
        :zIndex="1000"
        :title="dialog.type === 'add'? 'Add New Amenity' : 'Edit Amenity'"
        :width="'50%'"
        @close="onClose"
        :visible="dialog.show"
        :drawerStyle="{height: 'calc(100% - 50px)',overflow: 'auto'}"
        :maskClosable="false"
    >
    <bh-loading :show="loading" />
    <a-form-model  class="h-full dF fC jSB" ref="newAmenity" :model="newAmenity">
        <div class="f1">
            <a-row :gutter="16" v-if="hasChildren">
                <a-col :span="24">
                    <a-form-model-item label="Inherit data from" prop="inherit">
                        <a-select v-model="newAmenity.inherit">
                            <a-select-option key='none' value='none'>Don't inherit</a-select-option>
                            <a-select-opt-group v-for="children in childrenLots" :key="children.id">
                                <span slot="label">{{children.name}}</span>
                                <a-select-option v-for="lot in children.lots" :key="lot.id" :value="lot.id">{{lot.name}}</a-select-option>
                            </a-select-opt-group>
                        </a-select>
                    </a-form-model-item>
                </a-col>
            </a-row>
            <hr />
            <template v-if="newAmenity.inherit == 'none'">
                <a-row :gutter="16">
                    <a-col :span="24">
                        <a-form-model-item required label="Amenity Name" prop="name" :rules="req('Please enter the amenity name')">
                            <a-input v-model="newAmenity.name" placeholder="Example: Soccer Field"></a-input>
                        </a-form-model-item>
                    </a-col>
                </a-row>
                <a-row :gutter="16">
                    <a-col :span="24">
                        <a-form-model-item label="Description">
                            <a-textarea
                            :rows="4"
                            placeholder="Description of the Amenity"
                            required
                            v-model="newAmenity.description"
                            />
                        </a-form-model-item>
                    </a-col>
                </a-row>
                <a-row :gutter="16">
                    <a-col :xs="{span:24}" :xl="{span:12}">
                        <a-form-model-item label="Featured Image" prop="thumbnail">
                            <ImageBox :type=2 :img="newAmenity.thumbnail" @callback="selectedThumbnail" />
                        </a-form-model-item>
                    </a-col>
                </a-row>
                <a-row :gutter="16">
                    <a-col  :xs="{span:24}" :xl="{span:12}">
                        <a-form-model-item label="Media" prop="image" >
                            <ImageBox :type=2 :img="newAmenity.image" @callback="selectedImg" />
                        </a-form-model-item>
                    </a-col>
                </a-row>
            </template>
            <template v-else>
                <a-row :gutter="16">
                    <a-col :span="24">
                        <a-form-model-item label="Amenity Name">
                            <a-input :disabled="true" :value="childLots[newAmenity.inherit].name"></a-input>
                        </a-form-model-item>
                    </a-col>
                </a-row>
                <a-row :gutter="16">
                    <a-col :span="24">
                        <a-form-model-item label="Description">
                            <a-textarea
                            :rows="4"
                            placeholder="Description of the Amenity"
                            required
                            :value="childLots[newAmenity.inherit].description"
                            :disabled="true"
                            />
                        </a-form-model-item>
                    </a-col>
                </a-row>
                <a-row :gutter="16">
                    <a-col :xs="{span:24}" :xl="{span:12}">
                        <a-form-model-item label="Featured Image">
                            <ImageBox :disabled="true" :type=2 :img="childLots[newAmenity.inherit].thumbnail" @callback="selectedThumbnail" />
                        </a-form-model-item>
                    </a-col>
                </a-row>
                <a-row :gutter="16">
                    <a-col  :xs="{span:24}" :xl="{span:12}">
                        <a-form-model-item label="Media" >
                            <ImageBox :disabled="true" :type=2 :img="childLots[newAmenity.inherit].image" @callback="selectedImg" />
                        </a-form-model-item>
                    </a-col>
                </a-row>
            </template>
        </div>
        <div
            :style="{
            position: 'absolute',
            left: 0,
            bottom:0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
            }"
        >
            <div class="df jSB">
                <div>
                    <a-button @click="deleteAmenity" v-if="dialog.type === 'edit'" style="width:120px; float:left" type="danger" ghost icon="delete">DELETE</a-button>
                </div>
                <div>
                    <a-button @click="onClose" :style="{marginRight: '8px'}" class="cancel-button" style="width:120px">
                    CANCEL
                    </a-button>
                    <a-button @click="submit" style="width:120px" type="primary" >{{dialog.type === 'add'? 'CREATE' : 'UPDATE'}}</a-button>
                </div>
            </div>
        </div>
    </a-form-model>
    </a-drawer>

</template>

<script>
import bhLoading from 'bh-mod/components/common/Loading'
import mime from 'mime-types'
import ImageBox from '@/components/siteplan/ImageBox'

export default {
    components:{bhLoading, ImageBox},
    data() {
        return {
            loading:false,
            fileType:mime.lookup({type:String, required:true}),
            newAmenity: {
                image:'',
                thumbnail:'',
                name:'',
                description:'',
                shapeId:'',
                shape:{},
                type:'info',
                inherit:'none',
                siteplan:this.$store.state.masterplan.appData.id
            }
        }
    },
    watch: {
        dialog:{
            handler(val) {
                if (!val.show) {
                    this.loading = false
                    this.newAmenity = {
                        thumbnail:'',
                        image:'',
                        name:'',
                        description:'',
                        shapeId:'',
                        shape:{},
                        type:'info',
                        inherit:'none',
                        siteplan:this.$store.state.masterplan.appData.id
                    }
                }
                else if (val.type == 'add') {
                    this.newAmenity.shapeId = String(this.pointInfo.point.id)
                    this.newAmenity.shape = this.pointInfo.point
                }else if (val.type === 'edit') {
                    let obj = JSON.parse(JSON.stringify(this.$store.state.masterplan.editingAmenity))
                    if (!obj.hasOwnProperty('inherit') || !obj.inherit || obj.inherit == '') obj.inherit = 'none'
                    else if (obj.inherit.id) obj.inherit = obj.inherit.id
                    this.newAmenity = obj
                }
                this.newTime = Date.now() + ''
            },
            deep: true
        }
    },
    computed: {
        childrenLots() {
          let children = []
          let usedLots = []
          let storeLots = Object.values(this.$store.state.masterplan.lots)

          storeLots.forEach(lot => {
            if (lot.hasOwnProperty('inherit') && lot.inherit != '' && !usedLots.includes(lot.inherit)) {
              usedLots.push(lot.inherit)
            }
          })
          if (Object.keys(this.$store.state.masterplan.children).length != 0) {
            Object.values(this.$store.state.masterplan.children).forEach(instance => {
              let child = {
                name:'',
                id:'',
                lots:[]
              }
              child.name = instance.name
              child.id = instance.id
              if (instance.siteplan && instance.siteplan.lots && instance.siteplan.lots.length) {
                instance.siteplan.lots.forEach(instanceLot => {
                  if (this.dialog.type == 'add') {
                    if (instanceLot.type == 'info' && !usedLots.includes(instanceLot.id)) {
                      let lot = instanceLot
                      child.lots.push(lot)
                    }
                  } else if (this.dialog.type == 'edit') {
                    if (instanceLot.type == 'info') {
                      let lot = instanceLot
                      child.lots.push(lot)
                    }
                  }
                })
              }
              children.push(child)
            })
          }
          return children
        },
        hasChildren() {
          if (Object.keys(this.$store.state.masterplan.children).length != 0 && Object.keys(this.$store.state.masterplan.childLots).length != 0) return true
          else return false
        },
        dialog() {
            return this.$store.state.masterplan.amenityDrawer
        },
        pointInfo() {
            return this.$store.state.masterplan.newAmenityInfo
        },
        instance(){
          return this.$store.state.instance
        },
        appData(){
          return this.$store.state.masterplan.appData
        },
        siteplanLots() {
            return Object.values(this.$store.state.masterplan.lots)
        },
        childLots() {
            return this.$store.state.masterplan.childLots
        }
    },
    methods: {
        req:msg=>({required:true,message:msg}),
        deleteAmenity() {
            let self = this
            this.$confirm({
            title: "Delete",
            content: h => <div>Do you want to delete this Community Amenity Lot. Deleting will permanently remove the Community Amenity Lot.</div>,
            okText: 'Delete',
            okType: 'danger',
            cancelText: 'Cancel',
            centered: true,
            onOk() {
                self.confirmDelete()
            },
            onCancel() {
                console.log('Cancel')
            }
            })
        },
        confirmDelete () {
            this.$api.delete(`/lots/${this.instance.id}/${this.appData.id}/${this.newAmenity.id}`)
                .then( ({data}) => this.onClose({type:'delete', data})).catch(err => {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			})
        },
        submit() {
            if(this.dialog.type === 'add') return this.createAmenity()
            return this.updateAmenity()
        },
        createAmenity() {
            this.$refs.newAmenity.validate(valid => {
                if (valid) {
                this.loading = true
                let sendObj = JSON.parse(JSON.stringify(this.newAmenity))
                sendObj.spots = this.pointInfo.settings.spots
                if (sendObj.inherit == 'none') sendObj.inherit = null
                else {
                    sendObj.name = ''
                    sendObj.description = ''
                }
                this.$api.post(`/lots/${this.instance.id}/${sendObj.siteplan}`, sendObj).then( ({data}) => {
                    this.loading = false
                this.onClose({type:'add',data})
                }).catch(err => {
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err))
					}
				}).finally(() => {
					this.loading = false
				})
                } else {
                return false;
                }
            });


        },
        updateAmenity() {
            this.$refs.newAmenity.validate(valid => {
                if (valid) {
                    this.loading = true
                let sendObj = JSON.parse(JSON.stringify(this.newAmenity))
                if (sendObj.inherit == 'none') sendObj.inherit = null
                else {
                    sendObj.name = ''
                    sendObj.description = ''
                }
                if (sendObj.siteplan.id) sendObj.siteplan = sendObj.siteplan.id
                this.$api.put(`/lots/${this.instance.id}/${sendObj.siteplan}/${sendObj.id}`, sendObj).then( ({data}) => {
                    this.loading = false
                    this.onClose({type:'edit',data})
                }).catch(err => {
					this.loading = false
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err))
					}
				})
                } else {
                return false;
                }
            });

        },
        selectedThumbnail(item) {
            this.newAmenity.thumbnail = item
        },
        selectedImg(item) {
            this.newAmenity.image = item
        },
        onClose({type='',data={}}) {
            if (this.dialog.type === 'add' && (!type || type === 'click')){
                let {settings,self,point} = this.pointInfo

                let index = settings.spots.findIndex(x => x.id === point.id)
                settings.spots.splice(index,1)
                self.addAction();
                self.redraw();
            }
            if (type === 'delete') console.log('DELETING', data.id)
            this.$store.dispatch('CLOSE_AMENITY',{type,data})
            $('[data-wcp-editor-toolbar-button-name=select]').click()
            // this.$refs.newAmenity.resetFields();
            // this.newAmenity.inherit = 'none'
        },
    }
}
</script>

<style>
.cancel-button.ant-btn {
  border-color: #ece9f1 !important;
  background-color: #ece9f1;
  color: #3f3356;
}
</style>
