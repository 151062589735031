<template>
    <div>
        <a-drawer
            :zIndex="1000"
            title="Color Settings"
            :width="'50%'"
            :visible="colorDrawer"
            :drawerStyle="{height: 'calc(100% - 50px)',overflow: 'auto'}"
            @close="onClose"
        >
            <bh-loading :show="loading" />

            <div v-if="hasChildren && Object.keys(this.$store.state.masterplan.children).length > 1" class="mb-4">
                <div>
                    <a-form-model-item label="Colorize lots by Project or by Lot Type?">
                        <a-select v-model="colorType" style="width: 100%;">
                            <a-select-option value="builder">
                                Project
                            </a-select-option>
                            <a-select-option value="unitType">
                                Lot Type
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                </div>
                <h6 class="text-purple fw-600 mt-5">Project Colors </h6><hr>
                <div v-for="(child, childI) in instances" :key="child.id+childI">
                    <a-row :gutter="16">
                        <a-col :span="24">
                            <strong>{{child.name}}</strong>
                        </a-col>
                    </a-row>
                    <a-row class="" :gutter="16">
                        <a-col :span="12">
                            <a-form-model-item label="Label" required>
                                <a-input :placeholder="child.name" v-model="child.label"></a-input>
                            </a-form-model-item>
                        </a-col>
                        <a-col :span="12">
                            <a-form-model-item label="Color">
                                <a-input style="width:42px" type="color" v-model="child.color"></a-input>
                            </a-form-model-item>
                        </a-col>
                    </a-row>
                </div>
            </div>
            <h6 class="text-purple fw-600">Lot Type Colors <em class="text-danger" v-if="!units.length">(No models found)</em></h6><hr>
            <div v-if="units && units.length">
				<template v-if="units.length">
					<div v-for="(unit, unitI) in units" :key="unitI">
						<a-row :gutter="16">
							<a-col :span="24">
								<strong>{{unit.name}}</strong>
							</a-col>
						</a-row>
						<a-row :gutter="16">
							<a-col :span="12">
								<a-form-model-item label="Label" required>
									<a-input :placeholder="unit.name" v-model="unit.label"></a-input>
								</a-form-model-item>
							</a-col>
							<a-col :span="12">
								<a-form-model-item label="Color">
									<a-input style="width:42px" type="color" v-model="unit.color"></a-input>
								</a-form-model-item>
							</a-col>
						</a-row>
					</div>
				</template>
				<div class="pl-3" v-else>
					<p>You must add models in the <strong>Home Model Configurator</strong>. Only then you are able to assign colour label</p>
				</div>
			</div>
			<div v-else>Add Models to assign Colours and Labels</div>
            <div :class="!units || !units.length ? 'mt-4' : '' " v-if="amenities && amenities.length">
                <h6 class="text-purple fw-600">Amenities</h6><hr>
                <div v-for="amenity in amenities" :key="amenity.id">
                    <a-row :gutter="16">
                        <a-col :span="24">
                            <strong>{{amenity.name}}</strong>
                        </a-col>
                    </a-row>
                    <a-row class="mt-3" :gutter="16">
                        <a-col :span="12">
                            <a-form-model-item label="Label" >
                                <a-input :placeholder="amenity.name" v-model="amenity.label"></a-input>
                            </a-form-model-item>
                        </a-col>
                        <a-col :span="12">
                            <a-form-model-item label="Color">
                                <a-input style="width:42px" type="color" v-model="amenity.color"></a-input>
                            </a-form-model-item>
                        </a-col>
                    </a-row>
                </div>
            </div>
            <div
            :style="{
                position: 'absolute',
                left: 0,
                bottom:0,
                width: '100%',
                borderTop: '1px solid #e9e9e9',
                padding: '10px 16px',
                background: '#fff',
                textAlign: 'right',
            }"
            >
                <div class="dF jSB">
                    <a-button class="cancel-button" style="width:120px" :style="{marginRight: '8px'}" @click="onClose">
                        CANCEL
                    </a-button>
                    <a-button @click="submit" type="primary" click="submitButton">SAVE SETTINGS</a-button>
                </div>
            </div>
        </a-drawer>
    </div>
</template>

<script>

import bhLoading from 'bh-mod/components/common/Loading'

let colors = [
    '#b2b5e3',
    '#98e5bb',
    '#ec96e1',
    '#e4bae6',
    '#b1c5e6',
    '#9bcfb3',
    '#cfaba7',
    '#aedbcf',
    '#fce5a9',
    '#d0a584',
    '#b4b2ed',
    '#e2cdbb',
    '#95aabd',
    '#f5f0c0',
    '#b0b498',
    '#f498c4',
    '#adedfd',
    '#84acae',
]

let usedColors = []
let availableColors = () => colors.filter(x => !usedColors.includes(x))
let getColor = () => {

    let clrs = availableColors()
    if (!clrs.length){
        usedColors = []
        clrs = colors
    }
    let random = () => Math.floor(Math.random() * clrs.length)
    let clr = clrs[random()]
    usedColors.push(clr)
    console.log('RETURNING COLOR', clr)
    return clr

}


export default {
    data() {
        return {
            // pickr: null,
            options: {},
            color:'',
            instances:[],
            units:[],
			amenities:[],
            colorType:'unitType',
            loading:false
        }
    },
    components:{
        bhLoading
    },
    watch:{
        colorDrawer:{
            handler(val) {
                if (val){
                    let options = this.storeOptions || {}
                    if (!options.instances) options.instances = {}
                    if (!options.types) options.types = {}
                    if (!options.amenities) options.amenities = {}

                    let {instances,types,amenities} = options

                    if (this.childrenInstances && Object.values(this.childrenInstances).length){
                        Object.values(this.childrenInstances).forEach(inst => {
                            let object = {}
                            object.id = inst.id
                            object.name = inst.name
                            object.label = inst.name
                            object.color = '#ffffff'

                            if (instances[inst.id] && instances[inst.id].label) object.label = instances[inst.id].label
                            if (instances[inst.id] && instances[inst.id].color) object.color = instances[inst.id].color

                            this.instances.push(object)
                        })
                    }


                    if (this.storeAmenities && Object.values(this.storeAmenities).length){
                        Object.values(this.storeAmenities).forEach(x => {
                            let object = {}
                            object.id = x.id
                            object.name = x.name
                            object.label = x.name
                            object.color = '#ffffff'
                            if (amenities[x.id] && amenities[x.id].label) object.label = amenities[x.id].label
                            if (amenities[x.id] && amenities[x.id].color) object.color = amenities[x.id].color
                            this.amenities.push(object)
                        })
                    }

                    let allTypes = this.parentTypes
                    if (this.hasChildren) allTypes = this.childTypes
                    window.allTypes = allTypes
                    window.theseTypes = types
                    if (allTypes && Object.values(allTypes).length){
                        Object.values(allTypes).forEach(type => {
                            let object = {}
                            object.name = type
                            object.label = type
                            object.color = '#ffffff'
                            if (types[type] && types[type].label) object.label = types[type].label
                            if (types[type] && types[type].color) object.color = types[type].color
                            this.units.push(object)
                        })
                    }
                }
            }
        }
    },
    computed:{
		storeAmenities() {
            if (Object.values(this.$store.state.masterplan.lots).length == 0) return []
            else {
                let lots = Object.values(this.$store.state.masterplan.lots)
                let amenities = {}
                lots.forEach(lot => {
                    if (lot.type == 'info') {
                        amenities[lot.id] = lot
                    }
                })
                return amenities
            }
        },
        hasChildren() {
            if (Object.keys(this.$store.state.masterplan.children).length != 0 && Object.keys(this.$store.state.masterplan.childLots).length != 0) return true
            else return false
        },
        storeOptions(){
            return this.$store.state.masterplan.appData.options
        },
        colorDrawer() {
            return this.$store.state.masterplan.colorDrawer
        },
        childrenInstances() {
            return this.$store.state.masterplan.children
        },
        childTypes() {
            return this.$store.getters.childType
        },
        parentTypes() {
            return this.$store.getters.parentType
        },
        siteplanId() {
            return this.$store.state.masterplan.appData.id
        }
    },
    methods:{
        onClose(){
            this.$store.commit('CLOSE_COLORS')
            this.instances = []
            this.units = []
			this.amenities = []
            this.colorType = 'unitType'
        },
        pickr(e,f) {
            console.log(e,f)
        },
        formValid(array) {
            let valid = true
            array.forEach(x => {
                if (x.label == '') {
                    valid = false
                    return valid
                }
            })
            return valid
        },
        submit() {
			let instances = {}
			let types = {}
			let amenities = {}
			this.instances.forEach(instance => {
				let obj = {}
				obj.label = instance.label
				obj.color = instance.color
				instances[instance.id] = obj
			})
			this.units.forEach(unit => {
				let obj = {}
				obj.label = unit.label
				obj.color = unit.color
				types[unit.name] = obj
			})
			this.amenities.forEach(x => {
				let obj = {}
				obj.label = x.label
				obj.color = x.color
				amenities[x.id] = obj
			})


			let sendObj = {}
			if (this.storeOptions && Object.keys(this.storeOptions).length !== 0 && this.storeOptions.media) {
				sendObj.media = this.storeOptions.media;
			}
			sendObj.instances = instances
			sendObj.types = types
			sendObj.amenities = amenities
			sendObj.colorType = this.colorType
			console.log('sendOBJ',sendObj)
			this.loading = true
			this.$api.put(`/siteplans/:instance/${this.siteplanId}`, {options:sendObj}).then(({data}) => {
				this.loading = false
				this.$store.commit('UPDATE_OPTIONS', data)
				this.onClose()
			}).catch(err => {
				this.loading = false
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			})
        }
    }
}

</script>

<style>
.cancel-button.ant-btn {
  border-color: #ece9f1 !important;
  background-color: #ece9f1;
  color: #3f3356;
}
</style>
